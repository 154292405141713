import React, { useRef, useState } from 'react'
import { NavContainer, NavItem } from '../styles/navbar'
import LogoImg from "../imgs/logo.png"
import { Colors, DivHolder, DivHolderSpaced, Image, RoundedButtonContact, Text } from '../styles/container'
import { ListItemText, Menu, MenuItem, MenuList, Snackbar, TextField, useMediaQuery } from "@mui/material";
import { CodeIcon, EmailIcon, MenuIcon } from '../styles/icons';

function Navbar() {

  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const LargeScreenItems = () => {
    return (
      <DivHolder style={{ margin: '0px 30px' }}>
        <NavItem onClick={() => handleScrollToElement("home")}>Home</NavItem>
        <NavItem onClick={() => handleScrollToElement("about")}>About</NavItem>
        <NavItem onClick={() => handleScrollToElement("services")}>Services</NavItem>
        <NavItem onClick={() => handleScrollToElement("work")}>Work</NavItem>
        <NavItem onClick={() => handleScrollToElement("articles")}>Articles</NavItem>

      </DivHolder>
    )
  }

  const handleScrollToElement = (id) => {
    handleClose();
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <NavContainer>
      <DivHolderSpaced>
        <DivHolder>
          <Image src={LogoImg} width="150px" height="200px" />
          {!isSmallScreen && <LargeScreenItems />}
        </DivHolder>
        <DivHolder>
          <RoundedButtonContact onClick={() => handleScrollToElement("contact")}>{isSmallScreen ? <EmailIcon /> : "Contact us"}</RoundedButtonContact>
          {isSmallScreen && <MenuIcon
            size={"2rem"}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <ListItemText>Menu</ListItemText>
          </MenuIcon>}

        </DivHolder>


        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ sx: { py: '0px' } }}

        >
          <MenuList

            dense
            sx={{
              backgroundColor: `${Colors.orange}`,
              color: `${Colors.white}`,
              height: 'fit-content',
              padding: '10px 20px !important',
              margin: '0px',

              
            }}>
            <MenuItem sx={{ padding: '10px 0px', gap: '10px', margin: '0px' }} onClick={() => handleScrollToElement("home")}>
              <CodeIcon size={"1rem"} color={Colors.black} />
              <Text size={"0.8rem"} weight={600} letterSpacing={"1.1px"} textTransform={"uppercase"} color={Colors.black}>Home</Text>
            </MenuItem>
            <MenuItem sx={{ padding: '10px 0px', gap: '10px', margin: '0px' }} onClick={() => handleScrollToElement("about")}>
              <CodeIcon size={"1rem"} color={Colors.black} />
              <Text size={"0.8rem"} weight={600} letterSpacing={"1.1px"} textTransform={"uppercase"} color={Colors.black}>About</Text>
            </MenuItem>
            <MenuItem sx={{ padding: '10px 0px', gap: '10px', margin: '0px' }} onClick={() => handleScrollToElement("services")}>
              <CodeIcon size={"1rem"} color={Colors.black} />
              <Text size={"0.8rem"} weight={600} letterSpacing={"1.1px"} textTransform={"uppercase"} color={Colors.black}>Services</Text>
            </MenuItem>
            <MenuItem sx={{ padding: '10px 0px', gap: '10px', margin: '0px' }} onClick={() => handleScrollToElement("work")}>
              <CodeIcon size={"1rem"} color={Colors.black} />
              <Text size={"0.8rem"} weight={600} letterSpacing={"1.1px"} textTransform={"uppercase"} color={Colors.black}>Work</Text>
            </MenuItem>
            <MenuItem sx={{ padding: '10px 0px', gap: '10px', margin: '0px' }} onClick={() => handleScrollToElement("articles")}>
              <CodeIcon size={"1rem"} color={Colors.black} />
              <Text size={"0.8rem"} weight={600} letterSpacing={"1.1px"} textTransform={"uppercase"} color={Colors.black}>Articles</Text>
            </MenuItem>
          </MenuList>
        </Menu>

      </DivHolderSpaced>

    </NavContainer>
  )
}

export default Navbar