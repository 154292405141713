import React from 'react'

import LogoImg from "../imgs/logo.png"
import { FooterContainer } from '../styles/footer'
import { Colors, ColumnContainer, Image, Text } from '../styles/container'

function Footer() {
  return (
    <FooterContainer>
        <ColumnContainer>
        <Image style={{marginLeft: '-5px'}} src={LogoImg} width="auto" height="30px" />
        <Text color={Colors.white_400}>© 2024 Prolific & Co</Text>
        </ColumnContainer>
        
    </FooterContainer>
  )
}

export default Footer