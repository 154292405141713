import React, { useState, useEffect } from 'react'
import { ResetButton, StatusBlock, StatusContainer, TicBlock, TicContainer, TicRow } from "../styles/tictactoe"

function Square({value, onSquareClick}) {

    return <TicBlock onClick={onSquareClick}>{value}</TicBlock>
}

function TicTacToe() {

    const [squares, setSquares] = useState(Array(9).fill(null))
    const [xNext, setXNext] = useState(true)
    const [gameOver, setGameOver] = useState(false);
    const [status, setStatus] = useState(`Ready to play?`);

    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
      ];

    useEffect(() => {
        if(!xNext) compTurn()
    }, [xNext])
    
      function calculateWinner(squares) {
        console.log("Checking winner")
        let winner;
        for (let i = 0; i < lines.length; i++) {
          const [a, b, c] = lines[i];
          if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            winner = squares[a];
          }
        }

        if (winner) {
            if(winner === "X")
                setStatus("You Win!");
            else
                setStatus("You Lose!")    
            setGameOver(true);
          } else {
            let filled = true;
            for (let i = 0; i < squares.length; i++) {
              if (!squares[i]) {
                filled = false;
                break;
              }
            }
            if (filled) {
              setStatus("Game Over!");
              setGameOver(true);
            }
            else setStatus('Your turn!');
          }
      }
    
      function resetBoard() {
        setXNext(true)
        setStatus('Try to beat me!')
        setGameOver(false);
        setSquares(Array(9).fill(null))
      }

      function handleClick(i) {

        if (squares[i] || gameOver || !xNext) return;
    
        const nextSquares = squares.slice();
    
        if (xNext) nextSquares[i] = "X"
        setSquares(nextSquares)
        calculateWinner(nextSquares);
        
        setXNext(false);
    
      }

      // function compTurn() {
      //   let canWin = false;
      //   let canBlock = false;
      //   let indexWin;
      //   let indexBlock;

      //   if(gameOver) {
      //       console.log("Game over")
      //       return
      //   }
      
      //   for (let i = 0; i < lines.length; i++) {
      //     const [a, b, c] = lines[i];
      
      //     if (squares[a] === 'O' && squares[a] === squares[b] && !squares[c]) {
      //       indexWin = c;
      //       canWin = true;
      //       break;
      //     } else if (squares[a] === 'O' && squares[a] === squares[c] && !squares[b]) {
      //       indexWin = b;
      //       canWin = true;
      //       break;
      //     } else if (squares[b] === 'O' && squares[b] === squares[c] && !squares[a]) {
      //       indexWin = a;
      //       canWin = true;
      //       break;
      //     }
      //   }
      
      //   if (!canWin) {
      //     for (let i = 0; i < lines.length; i++) {
      //       const [a, b, c] = lines[i];
      
      //       if (squares[a] === 'X' && squares[a] === squares[b] && !squares[c]) {
      //         indexBlock = c;
      //         canBlock = true;
      //         break;
      //       } else if (squares[a] === 'X' && squares[a] === squares[c] && !squares[b]) {
      //         indexBlock = b;
      //         canBlock = true;
      //         break;
      //       } else if (squares[b] === 'X' && squares[b] === squares[c] && !squares[a]) {
      //         indexBlock = a;
      //         canBlock = true;
      //         break;
      //       }
      //     }
      //   }
      
      //   if (canWin) {
      //     const updatedSquares = [...squares];
      //     updatedSquares[indexWin] = 'O';
      //     setSquares(updatedSquares);
      //     calculateWinner(updatedSquares);
      //     setXNext(true);
      //   } else if (canBlock) {
      //     const updatedSquares = [...squares];
      //     updatedSquares[indexBlock] = 'O';
      //     setSquares(updatedSquares);
      //     calculateWinner(updatedSquares);
      //     setXNext(true);
      //   } else {
      //     let indexPlace;
      
      //     for (let i = 0; i < lines.length; i++) {
      //       const [a, b, c] = lines[i];
      
      //       if (!squares[a]) {
      //         indexPlace = a;
      //         break;
      //       } else if (!squares[b]) {
      //         indexPlace = b;
      //         break;
      //       } else if (!squares[c]) {
      //         indexPlace = c;
      //         break;
      //       }
      //     }
      
      //     if (indexPlace !== undefined) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[indexPlace] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //     }
      //   }
      // }


      // function compTurn() {
      //   if (gameOver) {
      //     console.log("Game over");
      //     return;
      //   }
      
      //   // Check if computer can win
      //   for (let i = 0; i < lines.length; i++) {
      //     const [a, b, c] = lines[i];
      //     if (squares[a] === 'O' && squares[a] === squares[b] && !squares[c]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[c] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     } else if (squares[a] === 'O' && squares[a] === squares[c] && !squares[b]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[b] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     } else if (squares[b] === 'O' && squares[b] === squares[c] && !squares[a]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[a] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     }
      //   }
      
      //   // Check if user can win and block
      //   for (let i = 0; i < lines.length; i++) {
      //     const [a, b, c] = lines[i];
      //     if (squares[a] === 'X' && squares[a] === squares[b] && !squares[c]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[c] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     } else if (squares[a] === 'X' && squares[a] === squares[c] && !squares[b]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[b] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     } else if (squares[b] === 'X' && squares[b] === squares[c] && !squares[a]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[a] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     }
      //   }
      
      //   // Prioritize center and corners
      //   const moveOptions = [4, 0, 2, 6, 8, 1, 3, 5, 7];
      
      //   for (let i = 0; i < moveOptions.length; i++) {
      //     const move = moveOptions[i];
      //     if (!squares[move]) {
      //       const updatedSquares = [...squares];
      //       updatedSquares[move] = 'O';
      //       setSquares(updatedSquares);
      //       calculateWinner(updatedSquares);
      //       setXNext(true);
      //       return;
      //     }
      //   }
      // }

      function compTurn() {
        if (gameOver) {
          console.log("Game over");
          return;
        }
      
        // Check if computer can win
        for (let i = 0; i < lines.length; i++) {
          const [a, b, c] = lines[i];
          if (squares[a] === 'O' && squares[a] === squares[b] && !squares[c]) {
            const updatedSquares = [...squares];
            updatedSquares[c] = 'O';
            setSquares(updatedSquares);
            calculateWinner(updatedSquares);
            setXNext(true);
            return;
          } else if (squares[a] === 'O' && squares[a] === squares[c] && !squares[b]) {
            const updatedSquares = [...squares];
            updatedSquares[b] = 'O';
            setSquares(updatedSquares);
            calculateWinner(updatedSquares);
            setXNext(true);
            return;
          } else if (squares[b] === 'O' && squares[b] === squares[c] && !squares[a]) {
            const updatedSquares = [...squares];
            updatedSquares[a] = 'O';
            setSquares(updatedSquares);
            calculateWinner(updatedSquares);
            setXNext(true);
            return;
          }
        }
      
        // Check if user can win and block
        for (let i = 0; i < lines.length; i++) {
          const [a, b, c] = lines[i];
          if (squares[a] === 'X' && squares[a] === squares[b] && !squares[c]) {
            const updatedSquares = [...squares];
            updatedSquares[c] = 'O';
            setSquares(updatedSquares);
            calculateWinner(updatedSquares);
            setXNext(true);
            return;
          } else if (squares[a] === 'X' && squares[a] === squares[c] && !squares[b]) {
            const updatedSquares = [...squares];
            updatedSquares[b] = 'O';
            setSquares(updatedSquares);
            calculateWinner(updatedSquares);
            setXNext(true);
            return;
          } else if (squares[b] === 'X' && squares[b] === squares[c] && !squares[a]) {
            const updatedSquares = [...squares];
            updatedSquares[a] = 'O';
            setSquares(updatedSquares);
            calculateWinner(updatedSquares);
            setXNext(true);
            return;
          }
        }
      
        // Prioritize center and corners
        if (!squares[4]) {
          const updatedSquares = [...squares];
          updatedSquares[4] = 'O';
          setSquares(updatedSquares);
          calculateWinner(updatedSquares);
          setXNext(true);
          return;
        }
      
        // Check if center is taken and a corner is available
        if (squares[4]) {
          const corners = [0, 2, 6, 8];
          for (let i = 0; i < corners.length; i++) {
            const corner = corners[i];
            if (!squares[corner]) {
              const updatedSquares = [...squares];
              updatedSquares[corner] = 'O';
              setSquares(updatedSquares);
              calculateWinner(updatedSquares);
              setXNext(true);
              return;
            }
          }
        }

        const oppositeCorners = [    [0, 8],
    [2, 6],
    [6, 2],
    [8, 0]
  ];
  for (let i = 0; i < oppositeCorners.length; i++) {
    const [corner1, corner2] = oppositeCorners[i];
    if (squares[corner1] === 'X' && !squares[corner2]) {
      const sides = [1, 3, 5, 7];
      for (let j = 0; j < sides.length; j++) {
        const side = sides[j];
        if (!squares[side]) {
          const updatedSquares = [...squares];
          updatedSquares[side] = 'O';
          setSquares(updatedSquares);
          calculateWinner(updatedSquares);
          setXNext(true);
          return;
        }
      }
    } else if (squares[corner2] === 'X' && !squares[corner1]) {
      const sides = [1, 3, 5, 7];
      for (let j = 0; j < sides.length; j++) {
        const side = sides[j];
        if (!squares[side]) {
          const updatedSquares = [...squares];
          updatedSquares[side] = 'O';
          setSquares(updatedSquares);
          calculateWinner(updatedSquares);
          setXNext(true);
          return;
        }
      }
    }
  }

  // Check for empty corner and take it
  const corners = [0, 2, 6, 8];
  for (let i = 0; i < corners.length; i++) {
    const corner = corners[i];
    if (!squares[corner]) {
      const updatedSquares = [...squares];
      updatedSquares[corner] = 'O';
      setSquares(updatedSquares);
      calculateWinner(updatedSquares);
      setXNext(true);
      return;
    }
  }

  // Take any empty square
  for (let i = 0; i < squares.length; i++) {
    if (!squares[i]) {
      const updatedSquares = [...squares];
      updatedSquares[i] = 'O';
      setSquares(updatedSquares);
      calculateWinner(updatedSquares);
      setXNext(true);
      return;
    }
  }
}

  return (
    <TicContainer>
        <TicRow>
            <Square value={squares[0]} onSquareClick={() => handleClick(0)} />
            <Square value={squares[1]} onSquareClick={() => handleClick(1)} />
            <Square value={squares[2]} onSquareClick={() => handleClick(2)} />
            
        </TicRow>
        <TicRow>
            <Square value={squares[3]} onSquareClick={() => handleClick(3)} />
            <Square value={squares[4]} onSquareClick={() => handleClick(4)} />
            <Square value={squares[5]} onSquareClick={() => handleClick(5)} />
            
        </TicRow>
        <TicRow>
            <Square value={squares[6]} onSquareClick={() => handleClick(6)} />
            <Square value={squares[7]} onSquareClick={() => handleClick(7)} />
            <Square value={squares[8]} onSquareClick={() => handleClick(8)} />
        </TicRow>
    
        <StatusContainer>
            <StatusBlock>{status}</StatusBlock>
            <ResetButton onClick={resetBoard}>Reset</ResetButton>
        </StatusContainer>
        
        
    </TicContainer>
  )
}

export default TicTacToe