import React, { useState } from 'react'
import { HeroContainer, HeroPanel } from '../styles/hero'
import { Colors, ColumnContainer, DivHolder, DivHolderSpaced, LineSeparator, Text, TextOutline } from '../styles/container'
import { ArrowIcon, ToggleIcon } from '../styles/icons'
import { Switch, ToggleButton, useMediaQuery } from '@mui/material'
import Navbar from './Navbar'
import TicTacToe from './TicTacToe'

function Hero() {

  const [toggleHero, setToggleHero] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width: 800px)')

  const HeroSwitch = () => {
    return (
      <DivHolder style={{ display: "block" }}>
        <Switch
          checked={toggleHero}
          sx={{
            width: '7rem',

            height: '4rem',
            '& .MuiSwitch-switchBase': {
              padding: 2,
              '&.Mui-checked': {
                transform: 'translateX(45px)',
                color: 'white', // Thumb color when checked
                '& + .MuiSwitch-track': {
                  backgroundColor: Colors.orange, // Track color when checked
                },
              },
            },
            '& .MuiSwitch-thumb': {
              width: '2rem',
              height: '2rem',
              backgroundColor: 'black', // Thumb color when not checked
            },
            '& .MuiSwitch-track': {
              borderRadius: 20,
              height: '2.5rem',
              backgroundColor: 'white', // Track color when not checked
              opacity: 1, // Ensure full color visibility
            },
          }}
          onChange={() => setToggleHero(prevState => !prevState)}
          color="primary"
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </DivHolder>

    );
  }


  return (
    <HeroContainer id="home">
      <Navbar />
      <HeroPanel>
        <DivHolderSpaced style={{ flexDirection: isSmallScreen ? "column" : "row" }}>
          <DivHolder style={{ flexWrap: 'wrap' }}>
            <Text style={{ gap: '30px' }} weight={600} size={isSmallScreen ? "2.5rem" : "3rem"} color={Colors.white}>Transform your vision

            </Text>
            
          </DivHolder>

          <TextOutline size={isSmallScreen ? "3rem" : "4rem"}>EST. 2023</TextOutline>
        </DivHolderSpaced>

        <LineSeparator color={Colors.background_grey_300} />
        <ColumnContainer style={{ width: '100%'}}>
          
          <DivHolder style={{flexWrap: 'wrap'}}>
          <Text style={{marginLeft: isSmallScreen ? "5px" : '30px'}} size={isSmallScreen ? "3rem" : "6rem"} weight={700} color={Colors.orange}>into digital reality
          
          </Text>
          <HeroSwitch />
          </DivHolder>
          

          <DivHolderSpaced style={{ marginRight: '40px', flexDirection: isSmallScreen ? "column" : "row" }}>
            {toggleHero ? <TicTacToe /> : <DivHolder></DivHolder>}
            <DivHolder>
              <ArrowIcon style={{ alignSelf: isSmallScreen ? "flex-start" : 'flex-end' }} size={"12rem"} />

              <Text style={{ maxWidth: isSmallScreen ? 'none' : '200px' }} size={"1.3rem"} color={Colors.white}>

                Crafting bespoke software solutions to transform your business into efficiency.</Text>
            </DivHolder>



          </DivHolderSpaced>

        </ColumnContainer>
        <LineSeparator color={Colors.background_grey_300} />

        <DivHolderSpaced style={{ marginTop: '20px' }}>
          <DivHolder style={{ maxWidth: '400px', marginRight: isSmallScreen ? "0px" : '40px' }}>
            <Text size={isSmallScreen ? "1.1rem" : "1.4rem"} color={Colors.white_400}>At Prolific, we specialize in delivering innovative software solutions tailored to meet the unique needs of businesses across various industries. Our mission is to drive digital transformation by integrating cutting-edge technology into everyday business operations, enhancing efficiency, and fostering growth.</Text>

          </DivHolder>
          <DivHolder style={{ marginTop: '10px', maxWidth: '700px' }}>
            <Text weight={700} size={isSmallScreen ? "3rem" : "6rem"} color={Colors.orange}>Lead the digital frontier.</Text>
          </DivHolder>

        </DivHolderSpaced>

      </HeroPanel>

    </HeroContainer>
  )
}

export default Hero