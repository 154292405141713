import styled from "styled-components";
import { Colors } from "./container";

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 70px;
    width: 100%;
    background-color: ${Colors.background_grey};
    color: white;
`