import styled from "styled-components";
import { Colors } from "./container";

export const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 110vh;
    padding-bottom: 60px;
    
    background-color: ${Colors.background_grey};
`

export const HeroPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 70px 50px;
    gap: 0px;

    @media (max-width: 800px) {
        padding: 70px 30px;
    }
`