import { IoToggle } from "react-icons/io5";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { FaCodeCommit } from "react-icons/fa6";
import { MdRoundaboutRight } from "react-icons/md";
import { MdLocalLaundryService } from "react-icons/md";

import { GiProcessor } from "react-icons/gi";
import { GiTeamDowngrade } from "react-icons/gi";
import { MdComputer } from "react-icons/md";
import { SiGoogleanalytics } from "react-icons/si";
import { MdPrecisionManufacturing } from "react-icons/md";
import { PiHandsClappingFill } from "react-icons/pi";

import { FaRunning } from "react-icons/fa";
import { GiJumpAcross } from "react-icons/gi";
import { HiOutlineEmojiHappy } from "react-icons/hi";

import { CgMenuGridO } from "react-icons/cg";
import { MdEmail } from "react-icons/md";
import { LuCode } from "react-icons/lu";


import styled from "styled-components";
import { Colors } from "./container";

export const ToggleIcon = styled(IoToggle)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size?  props.size : "1rem"};
`

export const ArrowIcon = styled(MdOutlineDoubleArrow)`
    color: ${props => props.color?  props.color : `${Colors.background_grey_400}`};
    font-size: ${props => props.size ? props.size : "1rem"};
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.orange};
    }
`

export const CommitIcon = styled(MdRoundaboutRight)`

    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const ServiceIcon = styled(MdLocalLaundryService)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const ProcessorIcon = styled(GiProcessor)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const TeamIcon = styled(GiTeamDowngrade)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const ComputerIcon = styled(MdComputer)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const AnalyticsIcon = styled(SiGoogleanalytics)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const PrecisionIcon = styled(MdPrecisionManufacturing)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const ClappingIcon = styled(PiHandsClappingFill)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const RunningIcon = styled(FaRunning)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const JumpIcon = styled(GiJumpAcross)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const HappyIcon = styled(HiOutlineEmojiHappy)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const MenuIcon = styled(CgMenuGridO)`
    color: ${props => props.color?  props.color : "white"};
    font-size: ${props => props.size ? props.size : "1rem"};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.orange};
    }
`

export const EmailIcon = styled(MdEmail)`
    color: ${props => props.color?  props.color : "inherit"};
    font-size: ${props => props.size ? props.size : "1rem"};
`

export const CodeIcon = styled(LuCode)`
    color: ${props => props.color?  props.color : "inherit"};
    font-size: ${props => props.size ? props.size : "1rem"};
`