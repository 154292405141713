import React, { useState } from 'react'
import { CaptchaContainer, ContactButton, ContactContainer, ContactForm, ContactInput, ContactTextArea } from '../styles/contact'
import { Colors, DivHolder, Image, Text } from '../styles/container'

import LogoSymbolImg from "../imgs/symbol.png"
import { ClappingIcon } from '../styles/icons'
import { Snackbar, useMediaQuery } from '@mui/material'

import emailjs from '@emailjs/browser';
import GoogleReCaptcha from 'react-google-recaptcha';

function Contact() {

  const isSmallScreen = useMediaQuery('(max-width: 800px)')

  const [showSnackbar, setShowSnackbar] = useState(false)
  const [barMessage, setBarMessage] = useState('')
  const [isHuman, setHuman] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const [formData, setFormData] = useState({
    company: '',
    name: '',
    email: '',
    contact: '',
    message: '',
  });

  const handleChange = (e, field) => {
    setFormData(prevState => {
      return {
        ...prevState,
        [field]: e.target.value
      }

    })
  };

  const onCaptchaChange = (value) => {
    setHuman(value !== null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // check if all fields filled
    if (!isHuman) {
      setBarMessage("Please verify that you are human.")
      setShowSnackbar(true)
      return;
    }

    if(formData.company === '' || formData.name === '' || formData.email === '' || formData.contact === '' || formData.message === '') {
      setBarMessage("Please fill in all fields before sending us a message.")
      setShowSnackbar(true)
      return;
    }

    setIsSending(true)
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      formData,
      {
        publicKey: 'oXJ8v4yGSzjQeHCCy'
      }

    ).then(
      (result) => {
        console.log(result.text);
        setBarMessage("Thanks for reaching out! We'll get back to you soon.")
        setShowSnackbar(true)
      },
      (error) => {
        console.log(error.text);
        setBarMessage("Something went wrong. Please try again later.")
        setShowSnackbar(true)
      }
    ).finally(() => {
      setIsSending(false)
      setFormData({
        company: '',
        name: '',
        email: '',
        contact: '',
        message: '',
      })
    })
  }

  return (
    <ContactContainer id="contact">

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={barMessage}
      />

      <DivHolder style={{ gap: '10px', marginBottom: '15px' }}>
        <Text size={isSmallScreen ? "1.5rem" : "2.5rem"} weight={700}>Get in touch
          <ClappingIcon size={isSmallScreen ? "2.5rem" : "3.5rem"} color={Colors.orange} /> Say Hello


        </Text>
      </DivHolder>

      <ContactForm onSubmit={handleFormSubmit}>
        <ContactInput type="text" required onChange={(e) => handleChange(e, "company")} value={formData.company} maxLength={50} placeholder="Company" />
        <ContactInput type="text" required onChange={(e) => handleChange(e, "name")} value={formData.name} maxLength={50} placeholder="Your Name" />
        <ContactInput type="email" required onChange={(e) => handleChange(e, "email")} value={formData.email} maxLength={50} placeholder="Email" />
        <ContactInput type="text" required onChange={(e) => handleChange(e, "contact")} value={formData.contact} maxLength={20} placeholder="Contact number" />
        <ContactTextArea required onChange={(e) => handleChange(e, "message")} value={formData.message} maxLength={500} placeholder="Message" rows="5" />

        <CaptchaContainer>
          <GoogleReCaptcha
            sitekey="6Lfm2PYpAAAAAGHtAosCIUB2bQJr9SedP9bdaouL"
            onChange={onCaptchaChange}
          />
        </CaptchaContainer>

        <ContactButton disabled={!isHuman || isSending}>Send us a message</ContactButton>

      </ContactForm>

    </ContactContainer>
  )
}

export default Contact