import React, { useEffect, useState } from 'react'
import { Drawer, DrawerCard, MainPanelContainer, ProjectContainer, WorkContainer } from '../styles/work'
import { Colors, ColumnContainer, DivHolder, Image, LineSeparator, Text, TextBubble } from '../styles/container'

import StorecallImg from "../imgs/storecall.png"
import MyReviewImg from "../imgs/myreview.png"
import FridayImg from "../imgs/friday.png"
import CKCMImg from "../imgs/ckcm.png"
import ProgasImg from "../imgs/progas.png"
import QadhaImg from "../imgs/qadha.png"
import ProfileCardImg from "../imgs/profilecard.png"
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material'

import MRImage1 from "../imgs/MR1.png"
import MRImage2 from "../imgs/MR2.png"

import MFImage1 from "../imgs/MF1.png"
import MFImage2 from "../imgs/MF2.png"

import SCImage1 from "../imgs/SC1.png"
import SCImage2 from "../imgs/SC2.png"

import CKCMImage1 from "../imgs/CKCM1.png"
import CKCMImage2 from "../imgs/CKCM2.png"

import PGImage1 from "../imgs/PG1.png"
import PGImage2 from "../imgs/PG2.png"

import QSImage1 from "../imgs/QS1.png"
import QSImage2 from "../imgs/QS2.png"

import PCImage1 from "../imgs/PC1.png"
import PCImage2 from "../imgs/PC2.png"

const ProjectItems = [
    {
        id: 1,
        name: "My Review",
        description: "Powerful PDF annotator and file reviewer tailored for accounting firms.",
        img: MyReviewImg,
        content: "My Review is designed to streamline the document review process for accounting firms. By creating a centralized platform for managing PDF annotations and queries, it enhances collaboration and accountability. Partners can easily track the progress of reviews, ensuring that all queries are addressed promptly. The gamification features motivate users to resolve queries efficiently, while the mobile accessibility ensures that reviews can be managed from anywhere. This powerful tool not only simplifies document reviews but also improves overall productivity and client satisfaction.",
        stack: "ReactJS, Node, Firebase, Google Cloud",
        owner: "Lockhat Incorporated",
        url: "",
        images: [MRImage1, MRImage2]
    },
    {
        id: 2,
        name: "My Friday",
        description: "Facilitates digital interactions between accounting firms and clients for requests for information.",
        img: FridayImg,
        content: "My Friday revolutionizes the way accounting firms interact with their clients by digitizing the RFI process. This platform ensures that all client interactions are streamlined and easily manageable. With customizable RFIs, firms can tailor their requests to each client's specific needs, enhancing the accuracy and efficiency of information gathering. The live progress tracking feature provides real-time updates, ensuring transparency and accountability throughout the engagement. By automating the annual recreation of engagements, My Friday saves valuable time and resources, allowing firms to focus on delivering high-quality services.",
        stack: "ReactJS, Node, Firebase, Google Cloud",
        owner: "Lockhat Incorporated",
        url: "",
        images: [MFImage1, MFImage2]
    },
    {
        id: 3,
        name: "Storecall",
        description: "A digital operational auditor that enhances process management and accountability.",
        img: StorecallImg,
        content: "Enables users to create custom templates by designing forms with dedicated questions and answer types. Operational users can conduct location audits using these templates, answer questions, rate services, attach images or files as proof, and assign actions to team members. Reports can be run to assess the performance of different locations, areas within a location, action performance, and manage user accountability. The centralized digital platform provides a live view of operational bottlenecks.",
        stack: "ReactJS, Node, Firebase, Google Cloud",
        owner: "Prolific",
        url: "https://www.storecall.co.za/",
        images: [SCImage1, SCImage2]
    },
    {
        id: 4,
        name: "CKCM Logistics",
        description: "A web app designed to capture and track courier orders efficiently.",
        img: CKCMImg,
        content: "CKCM Logistics' web app transforms the courier order process by offering a seamless and user-friendly interface. Customers can place orders effortlessly, with the app handling integration with multiple courier services to ensure efficient processing and tracking. The automated order addition to third-party systems eliminates manual entry errors and speeds up the delivery process. The tracking feature keeps customers informed about their parcel's status, enhancing transparency and customer satisfaction. This comprehensive solution streamlines operations and improves the overall courier service experience.",
        stack: "ReactJS, Node, 3rd Party Integrations",
        owner: "CKCM Logisitics",
        url: "https://www.ckcm.co.za",
        images: [CKCMImage1, CKCMImage2]
    },
    {
        id: 5,
        name: "LPG Cylinder",
        description: "An app to manage LPG cylinder rentals and invoicing for businesses.",
        img: ProgasImg,
        content: "The LPG Cylinder Rental app provides a robust solution for managing cylinder rentals and invoicing. By automating the calculation of rental amounts and tracking cylinder inventory, it reduces administrative workload and enhances accuracy. Businesses can create detailed customer profiles and tailor pricing for each customer, ensuring fair and precise billing. The role-based access control (RBAC) system enhances security by limiting access based on user roles. This app not only streamlines rental management but also supports better customer service and operational efficiency.",
        stack: "React, Node, Firebase",
        owner: "Prolific",
        url: "",
        images: [PGImage1, PGImage2]
    },
    {
        id: 6,
        name: "Qadha",
        description: "A qadha salaah tracker to manage and track outstanding prayers.",
        img: QadhaImg,
        content: "Qadha is designed to assist users in managing their religious obligations efficiently. The app simplifies tracking outstanding salaahs, providing a clear and organized way to monitor progress. With an easy-to-use interface, users can quickly update their records, ensuring they stay on top of their commitments. By offering a digital solution to track qadha salaahs, Qadha supports users in fulfilling their religious duties with greater ease and accountability.",
        stack: "React, Firebase",
        owner: "Prolific",
        url: "https://www.qadhasalaah.co.za/",
        images: [QSImage1, QSImage2]
    },
    {
        id: 7,
        name: "Profile Card",
        description: "A tool to create and design a digital profile card for social media channels.",
        img: ProfileCardImg,
        content: "Profile Card is an innovative solution for professionals looking to enhance their online presence without needing advanced technical skills. This tool allows users to create visually appealing digital business cards that consolidate all their essential information in one place. By including images, skills, services, and social media links, users can present a comprehensive and polished profile to their network. Profile Card simplifies the process of creating a professional digital footprint, making it easier for others to connect and engage with them.",
        stack: "React, Firebase",
        owner: "Prolific",
        url: "https://www.profilecard.co.za/",
        images: [PCImage1, PCImage2]
    },
]

const DrawerCardItem = ({ id, img, title, description, handleClick }) => {
    return (
        <DrawerCard onClick={() => handleClick(null, id)}>
            <DivHolder style={{ gap: '10px' }}>
                <Image src={img} width={"30px"} height={"30px"} />
                <ColumnContainer>
                    <Text textTransform={"uppercase"} letterSpacing={"1.1px"} size={"0.9rem"} color={Colors.white} weight={600}>{title}</Text>
                    <Text size={"0.8rem"} color={Colors.white_400}>{description}</Text>
                </ColumnContainer>
            </DivHolder>
        </DrawerCard>
    )
}

const Project = ({ loadedProject }) => {

    const isSmallScreen = useMediaQuery('(max-width: 800px)')

    return (<ProjectContainer>
        <DivHolder style={{ gap: '20px' }}>
            <Image src={loadedProject.img} width={isSmallScreen ? "60px" : "80px"} height={isSmallScreen ? "60px" : "80px"} />
            <ColumnContainer >
                <DivHolder style={{ gap: '0px 10px' }}>
                    <Text textTransform={"uppercase"} letterSpacing={"1.1px"} size={isSmallScreen ? "1.5rem" : "2.5rem"} color={Colors.orange} weight={700}>{loadedProject.name}</Text>

                </DivHolder>
                <TextBubble size={"0.8rem"} color={Colors.white_400} backgroundColor={Colors.black_600}>Project Owner - {loadedProject.owner}</TextBubble>

            </ColumnContainer>

        </DivHolder>

        <ColumnContainer style={{ marginTop: '20px', gap: '3px' }}>
            <Text size={"0.9rem"} color={Colors.orange}>Description</Text>
            <Text size={"1.2rem"} color={Colors.white_100}>{loadedProject.description}</Text>

        </ColumnContainer>

        <ColumnContainer style={{ gap: '3px' }}>
            <Text size={"0.9rem"} color={Colors.orange}>What's it about</Text>
            <Text size={"0.9rem"} color={Colors.white_400}>{loadedProject.content}</Text>
        </ColumnContainer>

        <ColumnContainer style={{ gap: '3px' }}>
            <Text size={"0.9rem"} color={Colors.orange}>Tech Stack</Text>
            <Text size={"0.9rem"} color={Colors.white_400}>{loadedProject.stack}</Text>
        </ColumnContainer>

        <ColumnContainer style={{ gap: '3px' }}>
            <Text size={"0.9rem"} color={Colors.orange}>Project Owner</Text>
            <Text size={"0.9rem"} color={Colors.white_400}>{loadedProject.owner}</Text>
        </ColumnContainer>

        {loadedProject?.images && loadedProject.images.length > 0 && <DivHolder style={{gap: '15px', marginTop: '20px', flexWrap: 'wrap'}}>
            {loadedProject.images.map((img, i) => {
                return <Image key={i} objectFit={"contain"} borderRadius={"10px"} src={img} width={"auto"} height={"300px"} />
            })}
        </DivHolder>}

    </ProjectContainer>)
}

const ProjectTabs = ({ selectedTab, handleChangeTab }) => {
    return <Box sx={{ marginTop: '30px', width: '100%' }}>
        <Tabs
            value={selectedTab} onChange={handleChangeTab} variant={"scrollable"}
            allowScrollButtonsMobile textColor='primary' aria-label="response-tabs"
            sx={{

                '& .MuiTabs-flexContainer': {

                    gap: '5px',
                },
                '.MuiTabs-scrollButtons': {
                    opacity: "1 !important",
                    color: `${Colors.orange}`
                },
            }}
        >
            {ProjectItems.map((project, i) => {
                return <Tab key={i} label={<Text size={"0.8rem"} color={Colors.white} textTransform={"uppercase"} letterSpacing={"1px"} weight={600}>{project.name}</Text>} value={project.id} />

            })}

        </Tabs>
    </Box>
}


function Work() {

    const [loadedProject, setLoadedProject] = useState(ProjectItems[0]);
    const [selectedTab, setSelectedTab] = useState(1);
    const isSmallScreen = useMediaQuery('(max-width: 800px)')

    const loadProject = (event, id) => {
        setSelectedTab(id);
        setLoadedProject(ProjectItems.find(item => item.id === id));
    }

    const SidePanel = () => {
        return (
            <Drawer>
                {ProjectItems.map((item) => {
                    return <DrawerCardItem key={item.id} id={item.id} img={item.img} title={item.name} description={item.description} handleClick={loadProject} />
                }
                )}
            </Drawer>
        )
    }

    const MainPanel = () => {
        return (<MainPanelContainer>
            <Project loadedProject={loadedProject} />
        </MainPanelContainer>)
    }

    return (
        <WorkContainer>
            {isSmallScreen ? <ProjectTabs selectedTab={selectedTab} handleChangeTab={loadProject} /> : <SidePanel />}

            <MainPanel />
        </WorkContainer>
    )
}

export default Work