import styled from "styled-components";
import { Colors } from "./container";

export const TicContainer = styled.div`
    width: 300px;
    height: 300px;
    z-index: 5;
    mix-blend-mode: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
        width: 250px;
        height: 250px;
    }

`

export const TicRow = styled.div`
    display: flex;
    
    
`

export const TicBlock = styled.div`
    font-size: 3rem;
    font-weight: 700;
    width: 80px;
    background-color: ${Colors.background_grey_400};
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid ${Colors.background_grey_200};
    
    padding: 5px 20px;

    @media (max-width: 800px) {
        width: 60px;
        height: 60px;
        font-size: 2rem;
    }
`

export const StatusContainer = styled.div`
    display: flex;
    
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`

export const StatusBlock = styled.div`
    
    text-align: center;
    margin-right: 10px;
    user-select: none;
    font-size: 1.1rem;
    font-weight: 700;
    color: ${Colors.orange};
    z-index: 3;

    @media (max-width: 800px) {
        font-size: 0.9rem;
    }
`

export const ResetButton = styled.div`
    text-align: center;
    font-size: 0.8rem;
    z-index: 6;
    cursor: pointer;
    padding: 5px 30px;
    border-radius: 20px;
    width: fit-content;
    align-self: center;
    text-transform: uppercase;
    
    border: 2px solid ${Colors.background_grey_100};
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
    transition: all 0.3s ease-in-out;

    &:hover {

    background-color: ${Colors.background_grey};
    
    color: rgba(255, 255, 255, 0.7);
    }


`