import React from 'react'
import { AboutBubble, AboutContainer, CommitmentCardContainer, CommitmentCardsHolder } from '../styles/about'
import { Colors, ColumnContainer, DivHolder, DivHolderSpaced, LineSeparator, RotatingContainer, Text } from '../styles/container'
import { HappyIcon, JumpIcon, RunningIcon } from '../styles/icons'
import { useMediaQuery } from '@mui/material'


const Spinner = ({children}) => {
    return <RotatingContainer>{children}</RotatingContainer>
}

function About() {

    const isSmallScreen = useMediaQuery('(max-width: 800px)')

    const Commitment = () => {
        return <ColumnContainer style={{padding: isSmallScreen ? "20px" : '20px 60px', marginTop: '30px'}}>
            <DivHolder style={{gap: '20px'}}>
            
            {/* <Text weight={700} size={"4rem"}  color={Colors.background_grey_400}>
            
                Our Commitment to <Text weight={700} size={"4rem"}  color={Colors.white}>You</Text>
                
            </Text>
            <Spinner>
            <CommitIcon color={Colors.background_grey_300} size={"10rem"} />
            </Spinner> */}
            
            </DivHolder>
            

            <CommitmentCardsHolder>
            <CommitmentCard topText={"Our"} icon={<RunningIcon />} index={1} title={"Quality and Precision"} message={"Committed to delivering high-quality solutions that are not only technically sound but also aligned with your business goals. Each project is meticulously crafted to ensure it meets the highest standards of excellence."} />
            <CommitmentCard topText={"Commitment"} index={2} icon={<JumpIcon />} title={"Customer Centric Approach"} message={"Providing exceptional customer service and support is at the core of our business. We work closely with our clients to understand their unique challenges and needs, ensuring we deliver solutions that exceed expectations."} />
            <CommitmentCard topText={"to You"} index={3} icon={<HappyIcon />} title={"Collaboration and Transparency"} message={"We believe in fostering strong relationships with our clients through open communication and transparency. Our collaborative approach ensures that you are involved at every stage of the project, making the process seamless and efficient."} />

            </CommitmentCardsHolder>
            
          
            

            </ColumnContainer>
    }

    const CommitmentCard = ({index, topText, icon, title, message, img}) => {
        return <ColumnContainer style={{ flex: 1, maxWidth: '350px', marginTop: index % 2 ? '0px' : `${isSmallScreen} ? "-20px" : "-80px"` }}>
            
            <DivHolder style={{gap: '10px', alignItems: 'flex-end'}}>
            
            <Text weight={700} size={isSmallScreen ? "2rem" : "3rem"} color={Colors.white_400}>{topText}</Text>
            {React.cloneElement(icon, {size: isSmallScreen ? "3.5rem" : "5rem", color: Colors.background_grey})}
            
            </DivHolder>
            
            <CommitmentCardContainer>
            <Text weight={700} size={"1.8rem"} letterSpacing={"1.2px"} color={Colors.background_grey_200}>{title}</Text>
            <LineSeparator height={"0.2rem"} color={Colors.background_grey_300} />
            <Text size={"1.1rem"} weight={600} color={Colors.black}>{message}</Text>
        </CommitmentCardContainer>
            </ColumnContainer>
            
    }

    return (
        <AboutContainer id="about">
            <AboutBubble>
                <Text color={Colors.white_500} textTransform={"lowercase"} size={isSmallScreen ? "1.4rem" : "1.8rem"}><LineSeparator height={"0.5rem"} width={"60px"} /> what makes us</Text>
                <Text weight={700} color={Colors.orange} textTransform={"lowercase"} size={"1.8rem"}>different</Text>
                <ColumnContainer>
                <Text size={isSmallScreen ? "1rem" : "1.1rem"} weight={500} color={Colors.white_400}>Founded in 2023, Prolific emerged from a deep-seated passion for software development and over a decade of operational experience in the large-scale FMCG industry. Our unique software development agency is driven by a profound understanding of the business world, enabling us to bridge the gap between technology and business needs seamlessly.
                    At Prolific, we pride ourselves on our team of highly skilled developers, designers, and business analysts who are dedicated to delivering top-notch software solutions tailored to your specific business requirements. Our expertise spans a wide range of industries, allowing us to offer customized solutions that drive efficiency, innovation, and growth.
                    
                </Text>
                <DivHolder style={{gap: '10px'}}>
                <LineSeparator color={Colors.background_grey_300} style={{marginTop: '20px'}} height={"0.5rem"} width={isSmallScreen ? "100px" : "300px"} />
                <Text style={{marginTop: '20px'}} color={Colors.background_grey_400} size={isSmallScreen ? "2rem" : "4rem"}>PROLIFIC</Text>
                </DivHolder>
                
                </ColumnContainer>
                

            </AboutBubble>

            
            <Commitment />
                


        </AboutContainer>
    )
}

export default About