import React, { useEffect } from 'react';
import { Colors } from '../styles/container';

const LinkedInFeed = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://widgets.sociablekit.com/linkedin-page-posts/widget.js';
    script.async = true;
    script.defer = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="articles" style={{display: 'flex', justifyContent: 'center', padding: '10px 0px'}}>
      <div className='sk-ww-linkedin-page-post' data-embed-id='25424628'></div>
    </div>
  );
};

export default LinkedInFeed;
