import styled from "styled-components";
import { Colors } from "./container";
import LogoSymbolImg from "../imgs/logo.png"

export const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    width: 100%;
    background: url(${LogoSymbolImg}) no-repeat center center/cover;
    background-position: left;
    background-size: contain;
    background-color: ${Colors.white_300};
`

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    background-color: ${Colors.white_100};
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px ${Colors.white_200};
`

export const ContactInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 0.9rem;

    font-family: 'Jost', sans-serif;
    border: none;
    outline: none;
    border-bottom: 2px solid ${Colors.white};
    
    &:focus {
        border-bottom: 2px solid ${Colors.orange};
    }
`

export const ContactTextArea = styled.textarea`
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    max-width: 100%;
    min-width: 200px;
    min-height: 200px;
    max-height: 200px;
    font-family: 'Jost', sans-serif;
    border-bottom: 1px solid ${Colors.orange};
    font-weight: 500;

    border: none;
    outline: none;
    
    &:focus {
        border-bottom: 2px solid ${Colors.orange};
    }
`

export const ContactButton = styled.button`

    padding: 10px;
    border-radius: 5px;
    
    background-color: ${Colors.background_grey_300};
    color: ${Colors.white};
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    outline: none;
    transition: 0.2s;
    &:hover {
        color: ${Colors.orange};
    }

    &:disabled {
        background-color: #ccc;
        color: ${Colors.white_200};
        cursor: not-allowed;
    
    }
`

export const CaptchaContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: center;
    z-index: 2;
`