import styled from "styled-components";
import { Colors } from "./container";

export const AboutContainer = styled.div`
    padding: 30px 0px;
    min-height: 600px;
    background-color: ${Colors.orange};

    @media (max-width: 800px) {
        padding: 20px 0px;
    }
`

export const AboutBubble = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box !important;
    flex: 1;
    max-width: 900px;
    width: 70%;
    gap: 20px;
    padding: 60px 60px;
    padding-bottom: 60px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    
    margin-top: -70px;
    background-color: ${Colors.background_grey_100};

    @media (max-width: 768px) {
        width: 90%;
        padding: 20px 40px;
    }
`

export const CommitmentCardsHolder = styled.div`
  display: flex;
  margin-top: 50px;
  gap: 40px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const CommitmentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  width: fit-content;
  
  background: url(${props => props.background}) no-repeat center center;
  background-size: cover;
  padding: 30px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background-color: ${Colors.white_400}; /* Your overlay color */
    opacity: 0.3; /* Adjust the opacity to make the overlay more or less transparent */
    z-index: -1; /* Make sure the overlay is behind the content */
  }



  /* Ensure that text and other content appear above the overlay */
  * {
    position: relative;
    z-index: 1;
  }
`;