import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import LinkedInFeed from "./components/LinkedInFeed";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import Work from "./components/Work";
import { AppContainer, Text } from "./styles/container";


function App() {
  return (
    <AppContainer>
      
      <Hero />
      <About />
      <Services />
      <Work />
      <Contact />
      <LinkedInFeed />
      <Footer />
    </AppContainer>
  );
}

export default App;
