import React, { useCallback, useState } from 'react';
import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { ProcessCardContainer, ProcessCardItem, ProcessCardItemBubble, ProcessCardItemHolder, ServicesContainer } from '../styles/services';
import { Colors, ColumnContainer, DivHolder, GridContainer, GridItem, LineSeparator, Text, TextOutline } from '../styles/container';
import CustomNodeFlow from './CustomNodeFlow';
import { AnalyticsIcon, ComputerIcon, PrecisionIcon, ProcessorIcon, ServiceIcon, TeamIcon } from '../styles/icons';
import { useMediaQuery } from '@mui/material';

const initialNodes = [
    {
        id: '1',
        data: { label: 'Exploration and Product Analysis', color: Colors.orange },
        position: { x: -150, y: 50 },
        type: 'input',
        style: { background: `#000814`, border: 'none', color: 'white' },
    },
    {
        id: '2',
        data: { label: 'Process Design and Planning' },

        position: { x: -100, y: 160 }, // Below Node 1 and a little to the right,
        style: { background: `${Colors.white_500}`, border: `none`, color: 'black' },
    },
    {
        id: '3',
        data: { label: 'Software Development' },
        style: { background: `${Colors.orange}`, border: `none`, color: 'black' },
        position: { x: 200, y: 150 }, // Same x as Node 1 but y further down

    },
    {
        id: '4',
        data: { label: 'Quality Assurance' },

        position: { x: 0, y: 300 }, // Below Node 3
        style: { background: `${Colors.background_grey_300}`, border: `none`, color: 'white' },
    },
    {
        id: '5',
        data: { label: 'Deployment and Maintenance' },
        position: { x: 300, y: 300 }, // To the right of Node 4
        style: { background: `${Colors.green}`, border: `none`, color: 'white' },
    },
];

const smallScreenNodes = [
    {
        id: '1',
        data: { label: 'Exploration and Product Analysis', color: Colors.orange },
        position: { x: -100, y: -100 },
        type: 'input',
        style: { background: `#000814`, border: 'none', color: 'white', fontSize: '0.9rem' },
    },
    {
        id: '2',
        data: { label: 'Process Design and Planning' },

        position: { x: 0, y: 0 }, // Below Node 1 and a little to the right,
        style: { background: `${Colors.white_500}`, border: `none`, color: 'black', fontSize: '0.9rem' },
    },
    {
        id: '3',
        data: { label: 'Software Development' },
        style: { background: `${Colors.orange}`, border: `none`, color: 'black', fontSize: '0.9rem' },
        position: { x: 200, y: 200 }, // Same x as Node 1 but y further down

    },
    {
        id: '4',
        data: { label: 'Quality Assurance' },

        position: { x: -50, y: 300 }, // Below Node 3
        style: { background: `${Colors.background_grey_300}`, border: `none`, color: 'white', fontSize: '0.9rem' },
    },
    {
        id: '5',
        data: { label: 'Deployment and Maintenance' },
        position: { x: -100, y: 400 }, // To the right of Node 4
        style: { background: `${Colors.green}`, border: `none`, color: 'white', fontSize: '0.9rem' },
    },
];

const nodeTypes = {
    custom: CustomNodeFlow,
};

const initialEdges = [
    { id: '1-2', source: '1', target: '2', label: '1', animated: true },
    { id: '2-3', source: '2', target: '3', label: '2', animated: true },
    { id: '3-4', source: '3', target: '4', label: '3', animated: true },
    { id: '4-5', source: '4', target: '5', label: '4', animated: true },
];


const rfStyle = {
    backgroundColor: `${Colors.background_grey}`,
    borderRadius: '10px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
};

const ProjectFlow = () => {
    
    const [edges, setEdges] = useState(initialEdges);
    const isSmallScreen = useMediaQuery('(max-width: 800px)')

    const [nodes, setNodes] = useState(isSmallScreen ? smallScreenNodes : initialNodes);

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );
    return (
        <ColumnContainer id="work" style={{marginTop: '80px'}}>
            <ColumnContainer style={{ position: 'relative', margin: "20px auto", textAlign: 'center' }}>
               
                <TextOutline style={{ zIndex: 3 }} color={Colors.orange} size={isSmallScreen ? "2rem" : "3rem"} weight={600}>Showcase Work</TextOutline>
                <PrecisionIcon style={{ fontSize: isSmallScreen ? "8rem" : '15rem', transform: 'translateX(-50%)', position: 'absolute', left: '50%', top: isSmallScreen ? '-20px' : "-100px", zIndex: '0', opacity: '0.3' }} />
            </ColumnContainer>

            <DivHolder style={{ width: '90%', margin: '0 auto', height: '600px' }}>
                <ReactFlow
                    nodes={nodes}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    onEdgesChange={onEdgesChange}
                    fitView
                    panOnDrag={false}
                    zoomOnScroll={false}
                    style={rfStyle}
                >

                </ReactFlow>
            </DivHolder>
        </ColumnContainer>
    )
}

const ServiceItems = () => {

    const isSmallScreen = useMediaQuery('(max-width: 800px)')

    return (
        <GridContainer>
            <GridItem>
                <Text size={isSmallScreen ? "1.1rem" : "1.6rem"} style={{marginBottom: '10px'}} weight={600} color={Colors.white}>
                    <ProcessorIcon color={Colors.orange} size={isSmallScreen ? "3rem" : "2rem"} />
                    Process Design and Mapping
                </Text>
                <Text color={Colors.white_400} size={"1rem"} weight={400}>Streamline your business operations with our comprehensive process design and mapping services. We analyze your current workflows, identify inefficiencies, and create optimized process maps that enhance productivity and reduce costs.</Text>
            </GridItem>
            <GridItem>
                <Text size={isSmallScreen ? "1.1rem" : "1.6rem"} style={{marginBottom: '10px'}} weight={600} color={Colors.white}>
                    <TeamIcon color={Colors.orange} size={isSmallScreen ? "3rem" : "2rem"} />
                    Digitize your workflows and collaboration</Text>
                <Text color={Colors.white_400} size={"1rem"} weight={400}>Transform your manual processes into efficient digital workflows. We implement advanced tools to facilitate seamless team collaboration, ensuring that your business operates smoothly and efficiently, no matter where your team is located.</Text>

            </GridItem>
            <GridItem>
                <Text size={isSmallScreen ? "1.1rem" : "1.6rem"} style={{marginBottom: '10px'}} weight={600} color={Colors.white}>
                    <ComputerIcon color={Colors.orange} size={isSmallScreen ? "3rem" : "2rem"} />
                    Bespoke app development
                    </Text>
                <Text color={Colors.white_400} size={"1rem"} weight={400}>Bring your unique business ideas to life with our bespoke app development services. Our expert developers create custom applications tailored to your specific needs, delivering innovative solutions that drive business growth and enhance user experiences.</Text>

            </GridItem>
            <GridItem>
                <Text size={isSmallScreen ? "1.1rem" : "1.6rem"} style={{marginBottom: '10px'}} weight={600} color={Colors.white}>
                    <AnalyticsIcon color={Colors.orange} size={isSmallScreen ? "3rem" : "2rem"} />
                    Data Analytics and Business Intelligence</Text>
                <Text color={Colors.white_400} size={"1rem"} weight={400}>Unlock the power of your data with our data analytics and business intelligence services. We provide insights and actionable strategies through advanced data analysis, helping you make informed decisions, optimize operations, and achieve your business goals.</Text>

            </GridItem>
        </GridContainer>
    )
}

const Services = () => {

    return (
        <ServicesContainer id="services">
            <ColumnContainer style={{ position: 'relative', margin: "20px auto", textAlign: 'center' }}>
                <Text color={Colors.white} style={{ alignSelf: 'center' }} textTransform={"uppercase"} weight={700} size={"3rem"}>Services</Text>
                <LineSeparator style={{ alignSelf: 'center' }} color={Colors.background_grey_200} width={"200px"} height={"0.2rem"} />
                
                <ServiceIcon style={{ fontSize: '10rem', transform: 'translateX(-50%)', position: 'absolute', left: '50%', top: '80px', zIndex: '0', opacity: '0.3' }} />
            </ColumnContainer>
            <ServiceItems />
            <ProjectFlow />
            
        </ServicesContainer>
    );
};

export default Services;
