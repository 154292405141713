import styled from "styled-components";
import { Colors } from "./container";

export const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    padding: 50px 0px;
    width: 100%;
    min-height: 100vh;
    background-color: ${Colors.background_grey_100};
`

export const ProcessCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    width: 100%;

`

export const ProcessCardItemHolder = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
`

export const ProcessCardItem = styled.div`
    display: flex;
    
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 400px;
    
    padding: 5px 20px;
    border-radius: 20px;
    background-color: ${Colors.background_grey_100};
    box-shadow: 0px 0px 10px 0px ${Colors.background_grey_200};
`

export const ProcessCardItemBubble = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 40px;
    height: 40px;
    color: ${Colors.white};
    background-color: ${Colors.background_grey_400};
    border-radius: 50%;
`