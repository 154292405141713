import { orange } from '@mui/material/colors';
import styled, {keyframes} from 'styled-components';

export const Colors = {
    transparent: "transparent",
    white: "#ffffff",
    white_100: "#f8f9fa",
    white_200: "#e9ecef",
    white_300: "#dee2e6",
    white_400: "#ced4da",
    white_500: "#adb5bd",

    background_grey: "#2B3043",
    background_grey_100: "#343A4B",
    background_grey_200: "#3E4457",
    background_grey_300: "#464D63",
    background_grey_400: "#4F556F",
    background_grey_500: "#575E7B",
    background_grey_600: "#60667F",
    background_grey_700: "#686F8B",
    background_grey_800: "#71778F",

    dark_black: "#0b090a",
    black: "#161a1d",
    black_100: "#1b1d1f",
    black_200: "#202326",
    black_300: "#25282c",
    black_400: "#2a2e33",
    black_500: "#2f333a",


    orange: "#F5793B",
    orange_100: "#F5793B",
    orange_200: "#F57A3B",
    orange_300: "#F57B3B",
    orange_400: "#F57C3B",
    orange_500: "#F57D3B",

    blue: "#001c55",
    blue_100: "#4361ee",
    blue_200: "#4362ee",
    blue_300: "#4363ee",
    blue_400: "#4364ee",
    blue_500: "#4365ee",

    green: "#52b788",
    green_100: "#52b788",
    green_200: "#52b788",
    green_300: "#52b788",
    green_400: "#52b788",
    green_500: "#52b788",
}

export const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${Colors.white_200};
    box-sizing: border-box !important;
`

export const DivHolder = styled.div`
  
  display: flex;
  margin: ${props => props.margin ? props.margin : "0px"};
  align-items: center;
  width: ${props => props.fullWidth ? '100%' : "auto"};
  gap: 5px;
  position: relative;
  border-radius: ${props => props.borderRadius ? props.borderRadius : "0px"};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.transparent}`};
  transition: all 0.2s ease-in-out;

`

export const DivHolderSpaced = styled.div`
  
  display: flex;
  align-items: center;
  width: ${props => props.fullWidth ? '100%' : "100%"};
  position: relative;
  box-sizing: border-box !important;
  gap: 5px 10px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
  justify-content: space-between;
  border-radius: 20px;
  flex-wrap: wrap;


`

export const ColumnContainer = styled.div`
display: flex;
flex-direction: column;
box-sizing: border-box !important;

`

export const Text = styled.div`

    font-size: ${props => props.size ? props.size : "0.8rem"};
    display: flex;
    gap: 10px;
    align-items: center;
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "0px"};
    text-transform: ${props => props.textTransform ? props.textTransform : "none"};
    font-style: ${props => props.fontStyle ? props.fontStyle : "normal"};
    font-weight: ${props => props.weight ? props.weight : 500};
    color: ${props => props.color ? props.color : `${Colors.background_grey}`}
`

export const TextBubble = styled.div`
    font-size: ${props => props.size ? props.size : "0.8rem"};
    display: flex;
    gap: 10px;
    padding: 5px 10px;
    width: fit-content;
    align-items: center;
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "0px"};
    text-transform: ${props => props.textTransform ? props.textTransform : "none"};
    font-style: ${props => props.fontStyle ? props.fontStyle : "normal"};
    font-weight: ${props => props.weight ? props.weight : 500};
    color: ${props => props.color ? props.color : `${Colors.background_grey}`};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.background_grey_400}`};
    border-radius: 5px;
`

export const Image = styled.img`
    width: ${props => props.width ? props.width : "100px"};
    max-width: 100%;
    height: ${props => props.height ? props.height : "100px"};
    object-fit: ${props => props.objectFit ? props.objectFit : "contain"};
    border-radius: ${props => props.borderRadius ? props.borderRadius : "0px"};
    
`

export const RoundedButtonContact = styled.button`
    padding: 5px 20px;
    border-radius: 20px;
    background-color: ${Colors.orange};
    color: ${Colors.white};
    font-weight: 600;
    display: flex;
    align-items: center;
    outline: none;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-family: 'Jost', sans-serif;
    cursor: pointer;
    border: none;
    transition: 0.2s;
    &:hover {
        background-color: ${Colors.white};
        color: ${Colors.orange};
    }
`

export const LineSeparator = styled.div`
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height ? props.height : "1.5px"};
    background-color: ${props => props.color ? props.color : Colors.white_300};
    margin: ${props => props.margin ? props.margin : "10px 0px"};
`

export const TextOutline = styled.div`
    font-size: ${props => props.size ? props.size : "0.8rem"};
    display: flex;
    gap: 10px;
    align-items: center;
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "0px"};
    text-transform: ${props => props.textTransform ? props.textTransform : "none"};
    font-style: ${props => props.fontStyle ? props.fontStyle : "normal"};
    font-size: ${props => props.size ? props.size : "0.8rem"};
    font-weight: ${props => props.weight ? props.weight : 500};
    color: ${props => props.color ? props.color : `${Colors.background_grey_400}`};
    border: 1px solid ${props => props.color ? props.color : `${Colors.background_grey}`};
    padding: 5px 10px;
    border-radius: 5px;
    transition: 0.2s;
    &:hover {
        color: ${Colors.white};
        background-color: ${Colors.orange};
    }
`

// Define the keyframes for the rotation animation
const rotate = keyframes`
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

// Create a styled component that applies the rotation animation
export const RotatingContainer = styled.div`
  display: inline-block;
  animation: ${rotate} 3s linear infinite alternate-reverse;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 50px;
  background-color: #0b090a;
  gap: 40px; /* This adds a gap between the grid items */
  padding: 80px 50px; /* This adds padding around the entire grid container */

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr); /* Adjust the number of rows to fit all items in one column */
  }
`;

export const GridItem = styled.div`
  padding: 20px;
  gap: 15px
`;