import styled from "styled-components";
import { Colors } from "./container";

export const WorkContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 10px 5px;
    min-height: 100vh;
    background-color: ${Colors.background_grey};

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

export const Drawer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 10px;
    border-radius: 10px;
    flex: 1;
    max-width: 300px;
    background-color: ${Colors.background_grey};
    
`

export const DrawerCard = styled.div`
    display: flex;
    gap: 5px;
    padding: 15px 10px;
    min-height: 80px;
    border-radius: 5px;
    background-color: ${Colors.dark_black};
    
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.black_200};
    }
`

export const MainPanelContainer = styled.div`
    display: flex;
    flex: 1;
    gap: 20px;
    background-color: ${Colors.white_100};
`
export const ProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
    background-color: ${Colors.background_grey_100};
`