import styled from "styled-components";
import { Colors } from "./container";

export const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 60px;
    width: 100%;
    background-color: ${Colors.background_grey};
    color: white;
`

export const NavItem = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 0 5px;
    color: ${Colors.white_300};
    font-size: 0.9rem;
    height: 100%;
    
    font-weight: 500;
    text-transform: lowercase;
    letter-spacing: 1.2px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        color: ${Colors.orange};
    }
`